import axios from 'axios'

export default function request(config){

    const baseURL = 'https://o.yushengtong.cn/'

    const instance = axios.create({
        baseURL:baseURL,
    })

    if((config.url === 'lf_wx_login') || (config.url === 'car_driver/register')) { //服务器
        instance.interceptors.response.use(success=>{
            if((success.data.status === 0) && (success.headers['x-token'])){
                sessionStorage['x-token'] = success.headers['x-token'];
            }
            return success.data
        },err=>{
            return err
        })
    } else {
        if((config.url !== 'car_driver/get_yzm') && (config.url !== 'car_driver/icard_file_a') && (config.url !== 'car_driver/icard_file_b') && (config.url !== 'car_driver/jscard_file_a') && (config.url !== 'car_driver/jscard_file_b'))
        {
            if(!sessionStorage.getItem('x-token')) {
                location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxccdebd924f18ecfc&redirect_uri=https://lfmobile.yushengtong.cn/lf_wx_login&response_type=code&scope=snsapi_base#wechat_redirect')    //跳转到登录页面（服务器）
                return
            }

            //请求拦截
            instance.interceptors.request.use(success=>{
                success.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('x-token')
                return success
            },err=>{
                return err
            })

            //响应拦截
            instance.interceptors.response.use(success=>{
                return success.data
            },err=>{
                return err
            })
        } else {
            //响应拦截
            instance.interceptors.response.use(success=>{
                return success.data
            },err=>{
                return err
            })
        }
    }

    return instance(config)
}